<template>

    <div class="row align-items-center date-controls">
        <div class="col-5 col-sm-4">
            <input
                type="radio" class="btn-check" name="options" autocomplete="off"
                id="fiveDays"
                :value="parseInt(5)"
                v-model="state.weekdaysToDisplay"
                v-on:change="changeValue()"
            >
            <label class="btn btn-outline-secondary nb-days me-1" for="fiveDays">5 {{ $t('dateTime.days') }}</label>

            <input
                type="radio" class="btn-check" name="options" autocomplete="off"
                id="sevenDays"
                :value="parseInt(7)"
                v-model="state.weekdaysToDisplay"
                v-on:change="changeValue()"
            >
            <label class="btn btn-outline-secondary nb-days" for="sevenDays">7 {{ $t('dateTime.days') }}</label>
        </div>

        <div class="col-4 d-none d-sm-block">
            <Datepicker
                v-model="state.newDate"
                :previewFormat="format"
                @update:modelValue="updateDate"
                :enableTimePicker="false"
                locale="fr"
                :format="format"
                name="date-picker"

            ></Datepicker>
        </div>

        <div class="col-7 col-sm-4 text-end">
            <button
                class="btn btn-primary me-2"
                @click.prevent="setToday()"
            >
                {{ $t('dateTime.Today') }}
            </button>

            <button
                class="btn btn-secondary prev-next me-1"
                @click.prevent="previousWeek()"
            >
                <i class="bi bi-chevron-left"></i>
            </button>
            <button
                class="btn btn-secondary prev-next"
                @click.prevent="nextWeek()"
            >
                <i class="bi bi-chevron-right"></i>
            </button>
        </div>
    </div>


</template>


<script>

import { reactive, onMounted } from 'vue'


// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

// import composables files
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'


export default {

    name: 'NavigationWeeks',

    components: {
        Datepicker,
    },

    props: {
        weekdaysToDisplay: Number,
        newDate: Date,
    },

    emits: [
        'onWeekdaysToDisplayChange',
        'onDateChange',
    ],

    setup(props, ctx) {

        const useDateTimeUtils = useDateTimeUtilsComp()

        const state = reactive({
            weekdaysToDisplay: '',
            newDate: new Date(),

        });

        // Format data
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`
        }


        onMounted(() => {
            state.weekdaysToDisplay = props.weekdaysToDisplay
        })

        function changeValue() {
            ctx.emit('onWeekdaysToDisplayChange', state.weekdaysToDisplay)
        }

        function nextWeek() {
            state.newDate = useDateTimeUtils.addOneWeek(state.newDate)
            updateDate()
            //getMeals()
        }

        function previousWeek() {
            state.newDate = useDateTimeUtils.substractOneWeek(state.newDate)
            updateDate()
            //getMeals()
        }

        function setToday() {
            state.newDate = new Date()
            updateDate()
            //getMeals()
        }

        function updateDate() {
            ctx.emit('onDateChange', state.newDate)
        }


        return {
            state,
            format,
            changeValue,
            updateDate,
            nextWeek,
            previousWeek,
            setToday,
        }
    },
}

</script>


<style>
    @import '../../assets/css/logo.css';
</style>
