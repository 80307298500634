<template>

    <div class="container-lg mb-3">

        <div class="header text-center text-md-start">
            <h3>
                <i class="bi bi-vinyl-fill"></i>
                {{ $t('titles.mealList') }}
            </h3>

            <DisplayCurrentWeek
                :currentDate="state.date"
            />
        </div>



        <div class="row align-items-center date-controls">
            <div class="col-5 col-sm-4">
                <input type="radio" class="btn-check" name="options" autocomplete="off" id="fiveDays" :value="parseInt(5)" v-model="state.weekdaysToDisplay" >
                <label class="btn btn-outline-secondary nb-days me-1" for="fiveDays">5 {{ $t('dateTime.days') }}</label>

                <input type="radio" class="btn-check" name="options" autocomplete="off" id="sevenDays" :value="parseInt(7)" v-model="state.weekdaysToDisplay">
                <label class="btn btn-outline-secondary nb-days" for="sevenDays">7 {{ $t('dateTime.days') }}</label>
            </div>

            <div class="col-4 d-none d-sm-block">
                <Datepicker
                    v-model="state.date"
                    :previewFormat="format"
                    @update:modelValue="updateDate"
                    :enableTimePicker="false"
                    locale="fr"
                    :format="format"
                    name="date-picker"

                ></Datepicker>
            </div>

            <div class="col-7 col-sm-4 text-end">
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="setToday()"
                >
                    {{ $t('dateTime.Today') }}
                </button>

                <button
                    class="btn btn-secondary prev-next me-1"
                    @click.prevent="previousWeek()"
                >
                    <i class="bi bi-chevron-left"></i>
                </button>
                <button
                    class="btn btn-secondary prev-next"
                    @click.prevent="nextWeek()"
                >
                    <i class="bi bi-chevron-right"></i>
                </button>
            </div>
        </div>

        <div class="row align-items-center date-controls">
            <span>{{ $t('form.actions.filtersOn') }}</span>

            <div
                v-if="state.filter.id === null"
                class="form-floating mb-3"
            >

                <input
                    id="customerFilter-empty"
                    type="text"
                    class="form-control rounded-4"
                    v-bind:class="formUtilsComp.isFieldEmpty(state.filter.id) ? 'is-invalid' : 'is-valid'"
                    v-model="state.filter.search"
                    @input="onFilterChange()"
                />
                <ul
                    class="list-group"
                    v-show="state.filter.isOpen"
                >
                    <li
                        class="list-group-item"
                        v-for="(result) in state.filter.customers"
                        :key="result.id"
                        @click="setFilterCustomerResult(result)"
                        @mouseover="mouseOver(result.id)"
                        @mouseout="mouseOut()"
                        v-bind:class="isMouseOvering(result.id) ? 'active' : ''"
                    >
                        id: {{ result.id }} -- {{ result.firstname }} {{ result.lastname }}, {{ result.postcode }} {{ result.city }}
                    </li>
                </ul>

                <label for="customerFilter-empty">
                    {{ $t('models.customer.customer') }}
                </label>

            </div>

            <div
                v-else
                class="form-floating mb-3"
            >
                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        v-bind:class="formUtilsComp.isFieldEmpty(state.filter.id) ? 'is-invalid' : 'is-valid'"
                        placeholder="Test field"
                        aria-label="Test field"
                        aria-describedby="button-addon3"
                        v-model="state.filter.search"
                        disabled
                    >
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        id="button-addon3"
                        @click="resetFilterSearchedCustomer()"
                    >X</button>
                </div>
            </div>
        </div>


        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="useMeals.state.form.response"
                ></AltertMessage>

            </template>

        </MyModal>


        <!-- ******************************************************** -->
        <!-- Display Meals list                                       -->
        <!-- ******************************************************** -->
        <div class="row mb-5">
            <div
                class="col-sm"
                v-for="n in state.weekdaysToDisplay"
                v-bind:key="n"
            >

                <div class="card text-center mt-4">
                    <div class="card-body date-header">
                        {{ useDateTimeUtils.getWeekDay(state.date, n-1).toLocaleDateString($i18n.locale, {weekday: 'short'}) }}
                            {{ useDateTimeUtils.getWeekDay(state.date, n-1).getDate() }}

                      <a
                          class="add-from-day border border-primary"
                          v-if="masterUser()"
                          @click="createResource()"
                      >
                          <i class="bi bi-plus-lg"></i>
                      </a>
                    </div>
                </div>

                <template
                    v-for="meal in useMeals.state.form.resources"
                    v-bind:key="meal"
                >

                    <div
                        v-if="(useDateTimeUtils.checkWeekDay(meal.date, n) && state.filter.id === null) || (useDateTimeUtils.checkWeekDay(meal.date, n) && (meal.customer_id === state.filter.id))"
                        class="card mt-3"
                        v-bind:class=" meal.group_id === null ? 'text-dark border-2 border-warning' : 'text-dark border-2 border-info'"
                    >

                        <div
                            class="card-body"
                            @click.prevent="showResource(meal)"
                        >
                            <p class="mb-1">{{ meal.lastname }} {{ meal.firstname }}</p>
                            <p class="mb-1">{{ meal.postcode }} {{ meal.city }}</p>
                            <p class="mb-1">{{ $t('models.meal.portions') }} : {{ meal.portion_qty }}</p>
                            <p class="mb-1">{{ $t('models.meal.half_portions') }} : {{ meal.half_portion_qty }}</p>
                            <p
                                class="mb-1 bg-danger text-white"
                                v-if="meal.comment"
                            >
                                {{ $t('models.meal.comment') }} : {{ meal.comment }}
                            </p>

                        </div>
                    </div>
                </template>
            </div>
        </div>

        <button
            v-if="masterUser()"
            class="btn btn-primary me-2 add-button"
            @click.prevent="createResource()"
        >
            <i class="bi bi-plus-lg"></i>
        </button>



        <MyModal
            v-bind:idProps="state.modal.changeGroupAlert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>
                <div class="row">
                    <div class="col">
                        <div v-if="useModals.state.modal.action === 'edit'" class="alert alert-warning" role="alert">
                            {{ $t('form.actions.edit') }} {{ $t('form.actions.question') }}
                        </div>
                        <div v-else class="alert alert-warning" role="alert">
                            {{ $t('form.actions.delete') }} {{ $t('form.actions.question') }}
                        </div>
                    </div>
                </div>

                <div
                    class="row"
                    v-if="useModals.state.modal.action === 'delete'"
                >
                    <div class="col">
                        <button
                            @click="deleteOnlyOne()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-info fs-6"
                        >{{ $t('form.scope.onlyOne') }}</button>
                    </div>
                    <div class="col" v-if="state.resourceToDeleteHasRepetition == true">
                        <button
                            @click="deleteSerieFromNow()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-warning fs-6"
                        >{{ $t('form.scope.fromNow') }}</button>
                    </div>
                    <div class="col" v-if="state.resourceToDeleteHasRepetition == true">
                        <button
                            @click="deleteCompleteSerie()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-danger fs-6"
                        >{{ $t('form.scope.all') }}</button>
                    </div>

                </div>

                <div
                    class="row"
                    v-else
                >
                    <div class="col">
                        <button
                            @click="editOnlyOne()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-info fs-6"
                        >{{ $t('form.scope.onlyOne') }}</button>
                    </div>
                    <div class="col" v-if="useMeals.state.form.body.group_id !== null">
                        <button
                            @click="editSerieFromNow()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-warning fs-6"
                        >{{ $t('form.scope.fromNow') }}</button>
                    </div>
                    <div class="col" v-if="useMeals.state.form.body.group_id !== null">
                        <button
                            @click="editCompleteSerie()"
                            class="w-100 mb-2 btn btn-lg rounded-4 btn-danger fs-6"
                        >{{ $t('form.scope.all') }}</button>
                    </div>
                </div>
            </template>
        </MyModal>


        <MyModal
            v-bind:idProps="state.modal.show"
            @click.prevent="useModals.state.modal.componentShow.toggle()"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>
                <table class="table">
                    <thead>
                        <th scope="col">{{ $t('objects.index') }}</th>
                        <th scope="col">{{ $t('objects.item') }}</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in state.models.mealToShow"
                            v-bind:key="item"
                        >
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="fs-6 fw-light text-decoration-underline mb-3">{{ $t('models.customer.information') }}</div>
                <div v-html="state.models.customerInfoToShow" class="ql-snow ql-editor"></div>
            </template>

            <template v-slot:footer v-if="masterUser()">
                <button
                    @click.prevent="copyResource(state.models.meal)"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-secondary"
                >{{ $t('form.actions.copy') }}</button>

                <button
                    @click.prevent="editResource(state.models.meal)"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >{{ $t('form.actions.edit') }}</button>

                <button
                    @click.prevent="deleteResource(state.models.meal)"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-warning"
                >{{ $t('form.actions.delete') }}</button>
            </template>
        </MyModal>




        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>
                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useMeals.useErrors.state.exception"
                    v-bind:exceptionMessage="useMeals.useErrors.state.exceptionMessage"
                ></ExceptionMessage>


                <template v-if="useModals.state.modal.action == 'edit'">
                    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </symbol>
                        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </symbol>
                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </symbol>
                    </svg>

                    <div v-if="state.editOnlyOneResource == true" class="alert alert-info d-flex align-items-center" role="alert">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
                        <div>
                            {{ $t('form.infos.edit.onlyOne') }}
                        </div>
                    </div>

                    <div v-else-if="state.editAllResources == false && state.editOnlyOneResource == false" class="alert alert-warning d-flex align-items-center" role="alert">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
                        <div>
                            {{ $t('form.infos.edit.fromNow') }}
                        </div>
                    </div>

                    <div v-else class="alert alert-warning d-flex align-items-center" role="alert">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
                        <div>
                            {{ $t('form.infos.edit.all') }}
                        </div>
                    </div>
                </template>


                <div class="form-floating mb-3">
                    <Datepicker
                        id="startDate"
                        :placeholder="$t('models.meal.startDate')"
                        v-model="useMeals.state.form.body.date"
                        :state="!formUtilsComp.isFieldEmpty(useMeals.state.form.body.date)"
                        :previewFormat="format"
                        @keyup.enter="saveResource()"
                        :enableTimePicker="false"
                        locale="fr"
                        :format="format"
                        name="date-picker"
                        required
                        vertical
                    ></Datepicker>

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.date)" class="text-info text-sm">
                        {{ $t('models.meal.validations.startDate') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.date"></ValidationErrors>
                </div>


                <div
                    v-if="state.form.id === null"
                    class="form-floating mb-3"
                >

                    <input
                        id="customer-empty"
                        type="text"
                        class="form-control rounded-4"
                        v-bind:class="formUtilsComp.isFieldEmpty(state.form.id) ? 'is-invalid' : 'is-valid'"
                        v-model="state.form.search"
                        @input="onFormChange()"
                    />
                    <ul
                        class="list-group"
                        v-show="state.form.isOpen"
                    >
                        <li
                            class="list-group-item"
                            v-for="(result) in state.form.customers"
                            :key="result.id"
                            @click="setFormCustomerResult(result)"
                            @mouseover="mouseOver(result.id)"
                            @mouseout="mouseOut()"
                            v-bind:class="isMouseOvering(result.id) ? 'active' : ''"
                        >
                            id: {{ result.id }} -- {{ result.firstname }} {{ result.lastname }}, {{ result.postcode }} {{ result.city }}
                        </li>
                    </ul>

                    <label for="customer-empty">
                        {{ $t('models.customer.customer') }}
                    </label>

                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(state.form.id)" class="text-info text-sm">
                        {{ $t('models.meal.validations.customer') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.customer_id"></ValidationErrors>

                </div>

                <div
                    v-else
                    class="form-floating mb-3"
                >
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control"
                            v-bind:class="formUtilsComp.isFieldEmpty(state.form.id) ? 'is-invalid' : 'is-valid'"
                            placeholder="Test field"
                            aria-label="Test field"
                            aria-describedby="button-addon2"
                            v-model="state.form.search"
                            disabled
                        >
                        <button
                            v-if="useModals.state.modal.action !== 'edit'"
                            class="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                            @click="resetFormSearchedCustomer()"
                        >X</button>
                    </div>
                </div>




                <template v-if="useModals.state.modal.action == 'create'">
                    <div class="form-floating mb-3">
                        <select
                            class="form-select rounded-4"
                            id="repetition"
                            v-model="useMeals.state.form.repetition"
                            @keyup.enter="saveResource()"
                        >

                            <option v-for="option in useMeals.getRepetitionsList()" :value="option.value" v-bind:key="option.value">
                                {{ option.text }}
                            </option>

                        </select>
                        <label for="active">
                            {{ $t('models.meal.repetitions.question') }}
                        </label>
                    </div>
                </template>

                <template v-if="useMeals.state.form.repetition >= 1">
                    <div class="form-floating mb-3">
                        <div
                            class="form-check form-check-inline me-2 mw-100 mt-3"
                            v-for="day in useDateTimeUtils.getWeekdaysShortOptionsList()"
                            v-bind:key="day.value"
                        >
                            <input v-model.number="useMeals.state.form.options.checkedDays" class="form-check-input" type="checkbox" :value="parseInt(day.value)">
                            <label class="form-check-label fs-6">{{ day.text }}</label>
                        </div>
                        <!-- Diplay frontend application alert message -->
                        <div class="text-info text-sm">
                            {{ $t('models.meal.validations.weekdays') }}
                        </div>
                    </div>

                    <div class="form-floating mb-3">
                        <select
                            class="form-select rounded-4"
                            id="endRepetition"
                            v-model="useMeals.state.form.endRepetition"
                            @keyup.enter="saveResource()"
                        >
                            <option v-for="option in useMeals.getEndRepetitionsList()" :value="option.value" v-bind:key="option.value">
                                {{ option.text }}
                            </option>

                        </select>
                        <label for="active">
                            {{ $t('models.meal.repetitions.type') }}
                        </label>
                    </div>

                    <div v-if="useMeals.state.form.endRepetition == 1" class="form-floating mb-3">
                        <input
                            type="number"
                            class="form-control rounded-4"
                            id="occurrences"
                            v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.occurrences) ? 'is-invalid' : 'is-valid'"
                            @keyup.enter="saveResource()"
                            placeholder="occurrences"
                            v-model="useMeals.state.form.occurrences"
                        >
                        <label for="occurrences">Occurrences</label>
                        <!-- Diplay frontend application alert message -->
                        <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.occurrences)" class="text-info text-sm">
                            {{ $t('models.meal.validations.occurrences') }}
                        </div>
                        <!-- Diplay alert message received from backend -->
                        <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.occurrences"></ValidationErrors>
                    </div>

                    <div v-if="useMeals.state.form.endRepetition == 0" class="form-floating mb-3">
                        <Datepicker
                            id="endDate"
                            :placeholder="$t('models.meal.endDate')"
                            v-model="useMeals.state.form.endDate"
                            :state="!formUtilsComp.isFieldEmpty(useMeals.state.form.endDate)"
                            :previewFormat="format"
                            @keyup.enter="saveResource()"
                            @open="openDateTimePickerEvent()"
                            @focus="focusOnDatePicker($event)"
                            :enableTimePicker="false"
                            locale="fr"
                            :format="format"
                            name="date-picker"
                            vertical
                            noSwipe
                        ></Datepicker>

                        <!-- Diplay frontend application alert message -->
                        <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.endDate)" class="text-info text-sm">
                            {{ $t('models.meal.validations.endDate') }}
                        </div>
                    </div>





                </template>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        class="form-control rounded-4"
                        id="portion_qty"
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.portion_qty) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="portion_qty"
                        v-model="useMeals.state.form.body.portion_qty"
                    >
                    <label for="portion_qty">
                        {{ $t('models.meal.portions') }}
                    </label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.portion_qty)" class="text-info text-sm">
                        {{ $t('models.meal.validations.portions') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.portion_qty"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control rounded-4"
                        id="portion_price"
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.portion_price) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="portion_price"
                        v-model="useMeals.state.form.body.portion_price"
                    >
                    <label for="portion_price">{{ $t('models.meal.portion_price') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.portion_price)" class="text-info text-sm">
                        {{ $t('models.meal.validations.portion_price') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.portion_price"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        class="form-control rounded-4"
                        id="half_portion_qty"
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.half_portion_qty) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="half_portion_qty"
                        v-model="useMeals.state.form.body.half_portion_qty"
                    >
                    <label for="half_portion_qty">
                        {{ $t('models.meal.half_portions') }}
                    </label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.half_portion_qty)" class="text-info text-sm">
                        {{ $t('models.meal.validations.half_portions') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.half_portion_qty"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        step="0.01"
                        class="form-control rounded-4"
                        id="half_portion_price"
                        v-bind:class="formUtilsComp.isFieldEmpty(useMeals.state.form.body.half_portion_price) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="half_portion_price"
                        v-model="useMeals.state.form.body.half_portion_price"
                    >
                    <label for="half_portion_price">{{ $t('models.meal.half_portion_price') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.half_portion_price)" class="text-info text-sm">
                        {{ $t('models.meal.validations.half_portion_price') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.half_portion_price"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="comment"
                        @keyup.enter="saveResource()"
                        placeholder="comment"
                        v-model="useMeals.state.form.body.comment"
                    >
                    <label for="comment">
                        {{ $t('models.meal.comment') }}
                    </label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useMeals.state.form.body.comment)" class="text-info text-sm">
                        {{ $t('models.meal.validations.comment') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useMeals.useErrors.state.errors.comment"></ValidationErrors>
                </div>


                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>
            </template>
        </MyModal>
    </div>
</template>


<script>

import { ref, reactive, onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'
import DisplayCurrentWeek from '@/components/dates/DisplayCurrentWeek.vue'

// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

import { useI18n } from 'vue-i18n'

import '@vueup/vue-quill/dist/vue-quill.snow.css'

// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useMealsComp from '@/composables/useMealsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'
import useModalsComp from '@/composables/useModalsComp.js'

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'meals',

    components: {
        Datepicker,
        MyModal,
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
        DisplayCurrentWeek,
    },

    setup() {
        const objUtils = useObjectsUtils()
        const useDateTimeUtils = useDateTimeUtilsComp()
        const formUtilsComp = useFormUtilsComp()
        const useMeals = useMealsComp()
        const useCustomers = useCustomersComp()
        const useModals = useModalsComp()

        const { masterUser } = useAuthComp()

        const { t } = useI18n({ useScope: 'global' })

        const newDate = ref(new Date())

        const state = reactive({
            date: new Date(),
            weekdaysToDisplay: 5,

            modal: {
                edit: 'editMealModal',
                show: 'showMealModal',
                alert: 'alertModal',
                changeGroupAlert: 'changeMealGroupAlertModal',
            /*
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null,
                componentGroupChangeAlert: null,
                componentAlert: null,
            */
            },

            events: {
                //isOpen: false,
                mouseOver: '',
            },

            form: {
                id: null,
                search: '',
                customers: [],
                isOpen: false,
                mouseOver: '',
            },

            filter: {
                id: null,
                search: '',
                customers: [],
                isOpen: false,
                mouseOver: '',
            },

            models: {
                meal: null,
                mealToShow: {},
                customerInfoToShow: '',
                selectedCustomer: '',
            },

            editOnlyOneResource: false,
            editAllResources: false,
            resourceToDelete: null,
            resourceToDeleteHasRepetition: false,

        })

        onMounted(() => {
            getMeals()

            //state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //state.modal.componentGroupChangeAlert = new Modal(document.getElementById(state.modal.changeGroupAlert))
            //state.modal.componentShow = new Modal(document.getElementById(state.modal.show))

            useModals.state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            useModals.state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            useModals.state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))
            useModals.state.modal.componentGroupChangeAlert = new Modal(document.getElementById(state.modal.changeGroupAlert))
        })

        function onFormChange() {

            onChange(state.form)
/*
            if (state.form.search.length >= 2) {

                useCustomers.searchResources(state.form.search)
                .then((res) =>{
                    //console.log(res)
                    mapCustomers(res)
                })

                state.events.isOpen = true
            } else {
                state.events.isOpen = false
            }
*/
        }

        function onFilterChange() {
            onChange(state.filter)
        }

        function onChange(obj) {

            if (obj.search.length >= 2) {

                useCustomers.searchResources(obj.search)
                .then((res) =>{
                    //console.log(res)
                    mapCustomers(obj, res)
                })

                obj.isOpen = true
            } else {
                obj.isOpen = false
            }
        }


        function mapCustomers(obj, dataArray) {
            //state.form.customers = []
            obj.customers = []

            for (var i = 0; i < dataArray.length; i++) {
                let dataObj = {}
                dataObj.id = dataArray[i].id
                dataObj.lastname = dataArray[i].lastname
                dataObj.firstname = dataArray[i].firstname
                dataObj.postcode = dataArray[i].postcode
                dataObj.city = dataArray[i].city
                //state.form.customers.push(obj)
                obj.customers.push(dataObj)
            }
        }

        function setFormCustomerResult(result) {
            setCustomerResult(state.form, result)
        }

        function setFilterCustomerResult(result) {
            setCustomerResult(state.filter, result)
        }

        function setCustomerResult(obj, result) {
            obj.id = result.id
            obj.search = result.firstname + ' ' + result.lastname
            obj.isOpen = false
        }

        function resetFormSearchedCustomer() {
            state.editOnlyOneResource = false
            resetSearchedCustomer(state.form)
        }

        function resetFilterSearchedCustomer() {
            resetSearchedCustomer(state.filter)
        }


        function resetSearchedCustomer(obj) {
            obj.id = null
            obj.search = ''
        }

        function mouseOver(id) {
            state.events.mouseOver = id
        }

        function mouseOut() {
            state.events.mouseOver = ''
        }

        function isMouseOvering(id) {
            return (state.events.mouseOver === id) ? true : false
        }


        function openDateTimePickerEvent() {

            useModals.state.modal.componentEdit.handleUpdate()

            //state.modal.componentEdit.scrollTo(0, document.body.scrollHeight)
            //window.scrollTo(0, document.body.scrollHeight);
        }

        function focusOnDatePicker(event) {
            console.log(event)
        }


        function getMeals() {
            useMeals.getMealsFromDateInterval({
                start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getSunday(state.date))
            })
        }

        function createResource() {
            // Reset customer search field
            resetFormSearchedCustomer()

            // Define modal attributes
            //state.modal.header = t('form.cards.meals.headers.create')
            //state.modal.action = 'Create'

            // Open Modal component
            //state.modal.componentEdit.toggle()


            useModals.setCreateModalAttributes( 'meals' )

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Flush user's attributes
            useMeals.flushAttributes()
        }


        function copyResource(resource) {

            // Reset customer search field
            resetFormSearchedCustomer()

            // Define modal attributes
            //state.modal.header = t('form.cards.meals.headers.create')
            //state.modal.action = 'Create'

            // Open Modal component
            //state.modal.componentEdit.toggle()

            useModals.setCreateModalAttributes( 'meals' )

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Flush user's attributes
            useMeals.flushAttributes()

            // Copy Meals attributes
            //useMeals.state.form.body.customer_id = resource.customer_id
            let customer = {
                id: resource.customer_id,
                lastname: resource.lastname,
                firstname: resource.firstname,
            }
            setFormCustomerResult(customer)


            useMeals.state.form.body.customer_id = resource.customer_id
            useMeals.state.form.body.group_id = resource.group_id
            useMeals.state.form.body.portion_qty = resource.portion_qty
            useMeals.state.form.body.portion_price = resource.portion_price
            useMeals.state.form.body.half_portion_qty = resource.half_portion_qty
            useMeals.state.form.body.half_portion_price = resource.half_portion_price
            useMeals.state.form.body.comment = resource.comment


        }


        function editResource(resource) {
            // Close show Modal component
            useModals.state.modal.componentShow.toggle()

            state.editOnlyOneResource = false
            let customer = {
                id: resource.customer_id,
                lastname: resource.lastname,
                firstname: resource.firstname,
            }
            setFormCustomerResult(customer)

            // Define modal attributes
            //state.modal.header = t('form.cards.meals.headers.edit')
            //state.modal.action = 'Edit'

            // Open Modal component
            //state.modal.componentGroupChangeAlert.toggle()

            useModals.setGroupChangeAlertModalAttributes( 'meals' )

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Clone user's attributes
            useMeals.state.form.body = objUtils.cloneObject(resource)
        }

        function editOnlyOne() {
            state.editOnlyOneResource = true
            state.editAllResources = false
            useMeals.state.form.body.group_id = null

            // Close Modal component
            useModals.state.modal.componentGroupChangeAlert.toggle()

            // Open Modal component
            useModals.state.modal.componentEdit.toggle()
        }

        function editSerieFromNow() {
            state.editOnlyOneResource = false
            state.editAllResources = false

            // Close Modal component
            useModals.state.modal.componentGroupChangeAlert.toggle()

            // Open Modal component
            useModals.state.modal.componentEdit.toggle()
        }

        function editCompleteSerie() {
            state.editOnlyOneResource = false
            state.editAllResources = true

            // Close Modal component
            useModals.state.modal.componentGroupChangeAlert.toggle()

            // Open Modal component
            useModals.state.modal.componentEdit.toggle()
        }


        function saveResource() {

            if ( useMeals.state.form.body.id == null ) {
                useMeals.state.form.body.customer_id = state.form.id

                useMeals.storeResource({
                    start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                    end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getSunday(state.date))
                })
                .then(() => {
                    useMeals.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()
                    useMeals.flushAttributes()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useMeals.state.form.response.alert = 'alert-danger'
                    useMeals.useErrors.useSetErrors(error.response.data)
                    console.log(error)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            } else {
                useMeals.state.form.body.update_all = state.editAllResources

                // If complete serie of resources to update
                useMeals.updateResource(
                    {
                        start: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getMonday(state.date)),
                        end: useDateTimeUtils.formatIsoDate(useDateTimeUtils.getSunday(state.date))
                    }
                )
                .then(() => {
                    useMeals.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()
                    useMeals.flushAttributes()

                    state.editOnlyOneResource = false
                    state.editAllResources = false

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    console.log(error)
                    useMeals.state.form.response.alert = 'alert-danger'
                    useMeals.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        // Create concatenated object to display
        function concatMealAndCustomerAttribute(meal, customer) {
            let obj = {}

            obj[t('models.meal.id')] = meal.id

            if (meal.group_id === null) {
                obj[t('models.meal.group_id')] = '---'
            } else {
                obj[t('models.meal.group_id')] = meal.group_id
            }

            obj[t('models.meal.invoice_id')] = meal.invoice_id
            obj[t('models.meal.date')] = useDateTimeUtils.showDate(new Date(meal.date))

            obj[t('models.customer.customerId')] = customer.id
            obj[t('models.customer.civility')] = customer.civility
            obj[t('models.customer.customer')] = customer.lastname.toUpperCase() + ' ' + customer.firstname
            obj[t('models.customer.address')] = customer.street + ' ' + customer.number
            obj[t('models.customer.locality')] = customer.postcode + ' ' + customer.city

            obj[t('models.meal.portions')] = meal.portion_qty
            obj[t('models.meal.half_portions')] = meal.half_portion_qty
            obj[t('models.meal.portion_price')] = meal.portion_price
            obj[t('models.meal.half_portion_price')] = meal.half_portion_price

            obj[t('models.meal.comment')] = meal.comment

            return obj
        }




        // Clone resource to update before insertion in form
        function showResource(resource) {
            // Define modal attributes
            //state.modal.header = t('form.cards.meals.headers.show')
            //state.modal.action = 'Show'

            // Open Modal component
            //state.modal.componentShow.toggle()

            useModals.setShowModalAttributes( 'meals' )

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Initialize the resource to show
            state.models.mealToShow = {}

            //let customer = {}
            useCustomers.showResource(resource.customer_id)
            .then((res) =>{
                // Set data to display in modal window
                state.models.mealToShow = objUtils.cloneObject( concatMealAndCustomerAttribute(resource, res) )
            })

            // Clone user's attributes
            state.models.meal = objUtils.cloneObject(resource)
            state.models.customerInfoToShow = resource.info
        }

        function deleteResource(resource) {
            // Close show Modal component
            useModals.state.modal.componentShow.toggle()

            state.resourceToDelete = null
            state.resourceToDeleteHasRepetition = false

            // Define modal attributes
            //state.modal.header = t('form.cards.meals.headers.delete')
            //state.modal.action = 'Delete'

            // Open Modal component
            //state.modal.componentGroupChangeAlert.toggle()

            useModals.setDeleteModalAttributes( 'meals' )

            state.resourceToDelete = resource

            if (resource.group_id !== null) {
                state.resourceToDeleteHasRepetition = true
            } else {
                state.resourceToDeleteHasRepetition = false
            }
        }

        function deleteOnlyOne() {
            useMeals.deleteResource(state.resourceToDelete.id)
            .then(() => {
                useModals.state.modal.componentGroupChangeAlert.toggle()

                useModals.displayAlertComponent()
            })
            .catch((error) => {
                useMeals.useErrors.useSetErrors(error.response.data)
            })
        /*
            .then(() => {
                useModals.displayAlertComponent()
            })
        */
        }

        function deleteCompleteSerie() {
            if ( state.resourceToDelete.group_id !== null ) {
                useMeals.deleteAllResourcesFromGroupId(state.resourceToDelete)
                .then(() => {
                    useModals.state.modal.componentGroupChangeAlert.toggle()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useMeals.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        function deleteSerieFromNow() {
            if ( state.resourceToDelete.group_id !== null ) {
                useMeals.deletelResourcesFromNowFromGroupId(state.resourceToDelete)
                .then(() => {
                    useModals.state.modal.componentGroupChangeAlert.toggle()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useMeals.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        // Formated date in YYYY-MM-DD to store in DB
        function formatDate(date) {
            let day = date.getDate()
            let month = date.getMonth() + 1
            let fullYear = date.getFullYear()
            let dateToShow = fullYear + '-' + month + '-' + day
            return dateToShow
        }

        function weekDay(date) {
            return date.getDay()
        }

        function nextWeek() {
            state.date = useDateTimeUtils.addOneWeek(state.date)
            getMeals()
        }

        function previousWeek() {
            state.date = useDateTimeUtils.substractOneWeek(state.date)
            getMeals()
        }

        function setToday() {
            state.date = new Date()
            getMeals()
        }

        function updateDate() {
            getMeals()
        }

        // Format data
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`
        }

        // ------------------------------------------------------------------
        // Functions to get the different lists with language translations
        // ------------------------------------------------------------------

        /**
         * Get the list for repetition request in the select options list
         *
         * @return {Array} List of selection possibilities
         */
    /*
        function getRepetitionsList() {
            let list = [
                { text: t('models.meal.repetitions.unique'), value: 0 },
                { text: t('models.meal.repetitions.every_week'), value: 10},
            ]
            return list
        }
    */

        /**
         * Get the list of repetition types possibilities in the select
         * options list
         *
         * @return {Array} List of repetition types
         */
    /*
        function getEndRepetitionsList() {
            let list = [
                { text: t('models.meal.repetitions.until_date'), value: 0 },
                { text: t('models.meal.repetitions.nb_occurrences'), value: 1 },
            ]
            return list
        }
    */

        /**
         * Get the list of the weekdays short to select in the checkbox options
         *
         * @return {Array} List of weekdsays short
         */
    /*
        function getWeekdaysShortOptionsList() {
            let list = [
                { text: t('form.options.weekdaysShort.monday'), value: 1, },
                { text: t('form.options.weekdaysShort.tuesday'), value: 2, },
                { text: t('form.options.weekdaysShort.wednesday'), value: 3, },
                { text: t('form.options.weekdaysShort.thursday'), value: 4, },
                { text: t('form.options.weekdaysShort.friday'), value: 5, },
                { text: t('form.options.weekdaysShort.saturday'), value: 6, },
                { text: t('form.options.weekdaysShort.sunday'), value: 0, },
            ]
            return list
        }
    */

        /**
         * Returned data and functions to the template
         */
        return {
            useMeals,
            useModals,
            mouseOver,
            mouseOut,
            isMouseOvering,
            newDate,
            formatDate,
            openDateTimePickerEvent,
            focusOnDatePicker,
            createResource,
            useDateTimeUtils,
            weekDay,
            state,
            format,
            nextWeek,
            previousWeek,
            setToday,
            formUtilsComp,
            updateDate,
            onFormChange,
            onFilterChange,
            setFormCustomerResult,
            setFilterCustomerResult,
            saveResource,
            showResource,
            copyResource,
            editResource,
            editOnlyOne,
            editSerieFromNow,
            editCompleteSerie,
            deleteResource,
            deleteOnlyOne,
            deleteSerieFromNow,
            deleteCompleteSerie,
            resetFormSearchedCustomer,
            resetFilterSearchedCustomer,
            masterUser,
            //getRepetitionsList,
            //getEndRepetitionsList,
            //getWeekdaysShortOptionsList,
        }
    }
}

</script>

<style>
@import '../../assets/css/logo.css';

.header h3 i {
  vertical-align: initial;
}

.week-date .date-long {
  font-style: italic;
  font-size: 13px;
}

.card {
    font-size: small;
}

.card .card-header {
    font-size: small;
}

.card .card-body {
    font-size: 14px;
    cursor: pointer;
}

.card .card-title {
    color: red;
}

.card .card-subtitle {
    color: blue;
}

.card .card-text {
    color: green;
}

.card-bg-coral {
    background-color: #EAC4B3;
}

.date-header {
    font-size: 10px;
    position: relative;
    cursor: default !important;
}

.date-header .add-from-day {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.date-header .add-from-day i {
    font-size: 12px;
    vertical-align: top;
}

.add-button {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    bottom: 10vh;
    left: calc(50% - 30px);
}

.add-button i {
  font-size: 30px;
}

.prev-next {
  padding: 0.375rem 0.4rem;
}

.nb-days {
  padding: 0.375rem 0.4rem;
}
</style>
